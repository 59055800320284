import PrefersDarkMode from '../../lib/prefersDarkMode';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';

/**
 * Renders an image using GatsbyImage allowing to specify images for both light and dark mode.
 *
 * @param {Object} param0 An object
 * @param {String} param0.alt The alternative text if the image can't be displayed
 * @param {Number} param0.mb The margin bottom value as is used by MUI
 * @param {Number} param0.mt The margin top value as is used by MUI
 * @returns {import('react').ReactComponentElement} A react component
 */
const Image = ({ src, srcLight, srcDark, alt, mb, mt }) =>
  srcLight.mimeType === 'image/svg+xml' ? (
    <Box
      component="img"
      mb={mb}
      mt={mt}
      src={
        PrefersDarkMode() && srcDark?.url
          ? `${srcDark.url}#svgView(preserveAspectRatio(xMidYMid meet))`
          : `${srcLight.url}#svgView(preserveAspectRatio(xMidYMid meet))`
      }
      sx={{ width: '100%', aspectRatio: '5 / 3' }}
      alt={alt}
    />
  ) : (
    <Box
      component={GatsbyImage}
      mb={mb}
      mt={mt}
      image={
        PrefersDarkMode() && srcDark?.gatsbyImageData
          ? srcDark?.gatsbyImageData
          : srcLight?.gatsbyImageData || src
      }
      alt={alt}
    />
  );
export default Image;
